.select_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media screen and (max-width: 528px) {
    grid-template-columns: repeat(1, 1fr);
  }
}
.select_wrapper {
  width: 100%;
  margin: 0.8rem 0;
}
.add_quiz_container {
  margin-top: 4rem;
}
