@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import "../styles/abstract/mixins";
@import "../styles/abstract/variables";

html {
  font-size: 16px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: $background-color;
  color: #2b2b2b;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  color: unset;
}

ul li {
  list-style-type: none;
}
.button {
  font-size: 1rem;
  padding: 0.4em 1.1em;
  border-radius: 4px;
  background-color: transparent;
  word-wrap: normal;
  overflow-wrap: normal;
  white-space: nowrap;
}
.button_primary {
  border: 1px solid $secondary-color;
  width: fit-content;
  &:hover {
    background-color: $secondary-color;
    color: white;
  }
}

.button_secondary {
  width: fit-content;
  border: 1px solid $primary-color;
  &:hover {
    background-color: $primary-color;
    color: white;
  }
}
.button_tertiary {
  width: fit-content;
  border: 1px solid $tertiary-color;
  &:hover {
    background-color: $tertiary-color;
    color: white;
  }
}
.required {
  color: $danger-color;
}
h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.625rem;
}
h3 {
  font-size: 1.375rem;
}
h4 {
  font-size: 1.25rem;
}
h5 {
  font-size: 1.15rem;
}
h6 {
  font-size: 1rem;
}

.loading {
  background-color: rgba(black, 0.1);
}

.required {
  color: $danger-color;
}
.error {
  margin: 0.6rem 0;
  color: $danger-color;
}

.pos-center {
  position: fixed;
  top: 50%;
  left: 55%;
}

