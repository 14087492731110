.quiz_card {
  box-shadow: 0 0 14px rgba(black, 0.2);
  img {
    height: 240px;
    object-fit: cover;
  }
  &:hover {
    transform: scale(1.05);
    transition: transform ease 0.2s;
  }
  .card-text {
    font-size: 1.4rem;
    text-align: center;
    margin: 1rem 0;
  }
}
