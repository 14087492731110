@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.sidebar {
  position: fixed;
  top: 2vh;
  left: 1vw;
  height: 96vh;
  width: 15vw;
  background-color: #fff;
  border-radius: 1vw;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

  .sidebar_logo {
    margin-bottom: 2rem;
    a {
      width: 100%;
      height: 100%;
      display: flex;
      .main_logo {
        margin: 1rem;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  &_logo {
    .zunun {
      text-decoration: none !important;
      color: unset;
    }
    // border: 1px solid red;
    padding: 0 0;
    @include flex-box-generic(flex-start, center, row);
    height: 90px;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;

    &_image {
      width: 40px;
      height: 40px;
      margin: 10px;
    }
  }

  &_menu {
    position: relative;

    :hover {
      color: $secondary-color;
    }

    &_item {
      @include flex-box-generic(flex-start, center, row);
      padding: 0.8rem 2rem;
      font-size: 0.9rem;
      font-weight: 500;
      color: #555555;
      transition: color 0.3s ease-in-out;

      &.active {
        color: #fff;
      }

      &_icon {
        // border: 1px solid red;

        @include flex-box-generic(center, center, row);
        margin-right: 1rem;
        width: 29px;
        min-width: 29px;
        background-color: #efefef;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

        height: 29px;
        border-radius: 10px;

        &.active {
          background-color: $secondary-color;

          :hover {
            color: white;
          }
        }
        i {
          font-size: 1rem;
        }
        @media screen and (max-width: 900px) {
          margin-right: 0.3rem;
        }
      }

      &_text {
        &.active {
          color: black;
          font-weight: 900;
        }
      }
    }

    // &_indicator {
    //   position: absolute;
    //   top: 0;
    //   left: 50%;
    //   width: calc(100% - 40px);
    //   border-radius: 10px;
    //   // background-color: #308efe;
    //   // background-color: #f1f1f1;
    //   z-index: -1;
    //   transform: translateX(-50%);
    //   transition: 0.3s ease-in-out;
    // }
  }
  @media screen and (max-width: 794px) {
    max-width: 72px;
  }
}

.sidebar_logo {
  display: flex;
  flex-direction: column;
}

//for responsive

@media screen and (max-width: 1058px) and (min-width: 794px) {
  .sidebar_menu_item {
    padding-left: 10px;
    padding-right: 0;
  }
  .sidebar_menu_item_text {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 794px) {
  .sidebar_logo_text {
    display: none;
  }
  // .sidebar{
  //   &:hover{
  //     width:300px;
  //     height:100vh;
  //   }
  // }
}

.sidebar_menu {
  @media screen and (max-width: 794px) {
    .sidebar_menu_item {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sidebar_menu_item_icon {
      height: 30px;
      width: 30px;
      margin: 0;
      padding: 0;
    }
    .sidebar_menu_item_text {
      display: none;
    }
  }
}
