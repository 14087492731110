.show_questions_container {
  margin-top: 2.5rem;
}

.edit_question_modal {
  .modal-dialog {
    min-height: 90vh;
    margin: 0;
    margin-top: 30px;
    margin-left: auto;
    .modal-content {
      min-height: 100vh;
    }
  }
  border: 1px solid blue;
}
.edit_answer_modal {
  .modal-dialog {
    min-height: 90vh;
    margin: 0;
    margin-top: 30px;
    margin-left: auto;
    .modal-content {
      min-height: 100vh;
    }
  }
  border: 1px solid blue;
}
