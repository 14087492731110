@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.header {
  h5 {
    margin: 0;
  }
  @include flex-box-generic(space-between, center, row);

  .actions {
    @include flex-box-generic(flex-end, center, row);

    width: 50%;

    i {
      font-size: 1.5em;
      &:hover {
        color: $secondary-color;
        cursor: pointer;
      }

      &.tick {
        font-size: 2em;
      }
    }

    Button {
      width: 45%;
    }
  }

  margin-bottom: 2em;
}

.content {
  width: 100%;
  // height: 27%;
  height: 100%;
  border: 1px solid rgb(196, 196, 196);
  padding-left: 2em;
  @include flex-box-generic(center, center, row);
  gap: 2em;

  .content_thumbnail {
    @include flex-box-generic(center, center, row);

    i {
      font-size: 3em;
    }
    position: relative;

    overflow: hidden;
    width: 125px;
    height: 60%;
    max-width: 200px;
    border: 1px solid grey;
    border-radius: 5px;
    @include flex-center-column;
    img {
      max-width: 100%;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      color: white;
      text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
      opacity: 0.95;
      margin-top: 5px;
    }
  }

  .content_about {
    width: 60%;
    height: 100%;
    margin-left: 0.5em;
    @include flex-box-generic(center, flex-start, column);
    gap: 0.75em;
    .content_title {
      h6 {
        margin: 0;
      }
    }

    .content_description {
      p {
        margin: 0;
        line-height: 150%;
        font-size: small;
        @include line-clamp(2);
      }
    }
  }

  .content_actions {
    height: 50%;
    margin-right: 5%;
    @include flex-box-generic(space-between, center, column);

    gap: 0.5em;
    i {
      margin-left: 2%;
      font-size: 1.2em;
      color: rgb(91, 86, 86);
      @include flex-box-generic(center, center, row);
      width: 27px;
      height: 27px;
      border-radius: 5px;
      background-color: rgb(228, 244, 251);

      &:hover {
        transform: scale(1.1);
        cursor: pointer;
      }

      &.add {
        color: rgb(49, 148, 49);
      }

      &.delete {
        color: rgb(236, 92, 92);
      }
    }
  }
}

.draggableList {
  // background: grey;
  padding: 7px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &.ordering {
    background: rgb(214, 232, 243);
  }
}

.draggableList::-webkit-scrollbar {
  display: none;
}
