.notification_container {
  .add_button_wrapper {
    max-width: 200px;
    margin: 1.5rem 0;
    margin-left: auto;
  }
  .btn_primary_notification{
    background-color: #3c88fb;
    color: antiquewhite;
    width: 50%;
    padding: 0%;
    
  }
  .btn_primary_notification:hover{
    background-color: #0d6efd;
  }
  
}
