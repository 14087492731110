@use "../../assets/styles/abstract/variables" as *;

.search_box {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;

  
  
}

.search_box button {
  background-color: white;
  border: grey 1px solid;
}

.search_box button:hover {
  background-color: rgba($secondary-color, 0.8);
  color:white;
}

.search_box input {
  height: 100%;
  padding: 0.8rem;
}

.btn {
  margin-left: 4px;
  height: 100%;
}

.form {
  position: relative;
  width: 100%;
  .form_input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid grey;
    border-radius: 1rem 4px 1rem 4px;
    font-family: inherit;
    color: black;
    outline: none;
  }

  .form_label {
    position: absolute;
    background-color: white;
    line-height: 1;
    border-radius: 8px;
    z-index: 2;
    left: 1.4rem;
    top: 0.7rem;
    padding: 0px;
    transition: top 0.5s ease-in-out;
  }
}

.search_box .form_input:focus ~ .form_label,
.form_input:not(:placeholder-shown).form_input:not(:focus) ~ .form_label {
  top: -0.6rem;
}
