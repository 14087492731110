@use "../../assets/styles/abstract/variables" as *;
@use "../../assets/styles/abstract/mixins" as *;

.navbar_container {
  @include flex-box-generic(space-between, center, row);
  height: 54px;
  width: 100%;
  background-color: white;
  padding: 0px 20px;
  border-top-left-radius: 1vw;
  border-top-right-radius: 1vw;
  border-bottom: 2px solid $background-color;
}
.menu_container {
  display: flex;
  position: relative;
  z-index: 4;
}
.navbar_pic_container {
  border: 1px solid grey;
  border-radius: 24px;
  height: 32px;
  width: 32px;
  object-fit: cover;
  object-position: center;
  @include flex-box-generic(center, center, row);
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
  }
}

#arrow_pic {
  padding-top: 6px;
  margin-left: 5px;
}

.menu_container .dropdown_menu {
  position: absolute;
  z-index: 3;
  background-color: white;
  width: 152px;
  top: 36px;
  right: 0px;
  padding: 8px 0px !important;
  border: 1px solid grey;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.dropdown_divider {
  width: 100%;
  padding: 0px;
  &:hover {
    background-color: white;
  }
}

.navbar_container a {
  display: flex;
  align-items: center;
  margin: 0px;
  list-style: none;
  padding: 10px 8px 10px 16px;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: rgb($secondary_color, 0.1);
  }
  li {
    margin-left: 0.4rem;
  }

  @media screen and (max-width: 428px) {
    padding: 8px 14px;
  }
}

.row-container {
  @include flex-box-generic(center, center, row);

  .greeting_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    padding: 4px;
    h6 {
      margin-bottom: 0;
      font-weight: 500;
    }
  }
  @media screen and (max-width: 592px) {
    margin-left: auto;
  }
}

.timeContainer {
  @include flex-box-generic(center, flex-start, row);
  width: fit-content;
  & span {
    font-weight: 500;
  }
  .time {
    width: 62px;
    font-weight: 400;

    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  @media screen and (max-width: 592px) {
    display: none;
  }
}
