@use "../../assets/styles/abstract/variables" as *;

.competition_container {
  display: flex;
  .competition_list {
    width: 50%;
    padding: 1.5rem;
    .competition_card_container {
      padding: 0.2rem;
      height: 100%;
      max-height: 70vh;
      margin-top: 0.5rem;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      .competition_card {
        box-shadow: 0 0 5px rgba(black, 0.2);
        margin: 1.1rem 0;
        padding: 1rem 0.5rem;

        .card_header {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          margin-left: 2rem;
        }
        .round_detail {
          margin-left: 2rem;
        }
        .published_toggle_container {
          display: flex;
          align-items: center;
          margin-left: auto;
        }

        .card_header .edit_btn {
          margin: 0;
          padding: 0;
          margin-left: 1.8rem;
          height: 18px;
          width: 18px;
          color: $secondary-color;
        }
        .card_header .close_btn {
          margin: 0;
          padding: 0;
          margin-left: 1rem;
          color: $danger-color;
          height: 24px;
          width: 24px;
        }

        .competition_name {
          font-size: 1rem;
          font-weight: 800;
          word-break: break-all;
        }
        .competition_detail {
          font-size: 0.9rem;
          display: flex;
          justify-content: flex-end;
          margin-top: 0.5rem;

          .start_time {
            display: flex;
            align-items: center;
            svg {
              height: 20px;
              width: 20px;
              margin: 0 0.4rem;
              color: black;
            }
          }

          .end_time {
            display: flex;
            align-items: center;
            svg {
              height: 20px;
              width: 20px;
              margin: 0 0.4rem;
              color: $danger-color;
            }
          }
        }
        .pin_icon{
          position: absolute;
          left: 2rem;
          height: 24px;
          width: 24px;
          cursor: pointer;
        }
        .start_time {
          margin-right: 1.2rem;
        }
        &:hover {
          border-color: $secondary-color;
        }
      }
      & > .active {
        box-shadow: 0 0 15px inset rgb(black, 0.1);
        border: 1px solid $secondary-color;
      }
    }
  }

  .tab_container {
    margin: 1rem 0;
    .nav-item {
      cursor: pointer;
    }
  }

  .competition_list .add_competition_btn_wrapper {
    width: 200px;
    margin-left: auto;
  }

  .round_container {
    width: 50%;
    padding: 1.5rem;
    .add_round_btn_wrapper {
      width: 100%;
      max-width: 160px;
      margin-left: auto;
    }
  }
}

.round_card_container {
  height: 100%;
  max-height: 60vh;
  overflow-y: scroll;
  width: 100%;
  padding: 0.4rem;
  &::-webkit-scrollbar {
    display: none;
  }
  .round_card {
    width: 100%;
    margin: 1rem 0;
    padding: 0.6rem;
    border-radius: 0.4rem;
    box-shadow: 0 0 10px rgba(black, 0.2);
  }
  span {
    padding: 0;
    margin: 0;
    margin-right: 0.9rem;
  }
  .round_card_header {
    display: flex;
    align-items: center;
    .round_name {
      font-weight: 400;
      margin: 0;
      padding: 0;
    }
    .card_buttons {
      margin-left: auto;
      svg {
        height: 18px;
        width: 18px;
        color: $secondary-color;
      }
    }
  }
  .round_card_body {
    display: flex;
    font-weight: 500;
    margin-top: 20px;
    gap: 2rem;
    .round_time {
      display: flex;
      align-items: center;
      svg {
        height: 20px;
        width: 20px;
        color: $secondary-color;
        margin-right: 0.4rem;
      }
    }
    .round_game {
      display: flex;
      align-items: center;

      svg {
        height: 20px;
        width: 20px;
        margin-right: 0.4rem;
      }
      font-weight: 500;
    }
  }
}
.hidden {
  visibility: hidden;
}

//ribbon

.competition_card {
  position: relative;
  // box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.ribbon_container {
  /* common */
  .ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  }
  .ribbon::before,
  .ribbon::after {
    position: absolute;
    z-index: 0;
    content: "";
    display: block;
  }
  .ribbon span {
    position: absolute;
    display: block;
    width: 100px;
    padding: 2px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    color: #fff;
    font: 700 8px/1 "Lato", sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-align: center;
  }

  /* top left*/
  .ribbon-top-left {
    top: -8px;
    left: -9px;
  }

  .ribbon-top-left::before {
    top: 0;
    right: 92px;
  }
  .ribbon-top-left::after {
    bottom: 94px;
    left: 0;
  }
  .ribbon-top-left span {
    right: 75px;
    top: 18px;
    z-index: 1;
    transform: rotate(-45deg);
  }
  //for ribbon color

  .Active {
    span {
      background-color: green;
    }
    &::after,
    &::before {
      border: 4px solid rgb(1, 124, 1);
    }
  }
  .Ended {
    span {
      background-color: rgb(128, 125, 125);
    }
    &::after,
    &::before {
      border: 4px solid rgb(117, 117, 117);
    }
  }
  .Coming {
    span {
      background: rgb(2, 0, 36);
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(221, 52, 101, 1) 35%,
        rgba(210, 206, 48, 1) 96%
      );
    }
    &::after,
    &::before {
      border: 4px solid rgb(170, 2, 2);
    }
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
}
