@use "../../assets/styles/abstract/variables" as *;

.sponsor_container {
  background-color: #fbfcfc;

  .add_button_wrapper {
    max-width: 160px;
    margin-left: auto;
  }

  .sponsor_card {
    margin: 2.5rem;
    padding: 0.3rem;

    max-width: 350px;
    background-color: #ffff;
    box-shadow: 0 4px 15px rgba(rgb(70, 65, 65), 0.2);

    .sponsor_card_header {
      h5 {
        font-size: 1.2rem;
        font-weight: 600;
      }

      p {
        font-weight: 100;
      }
    }

    .sponsor_card_body {
      display: flex;
      align-items: center;

      .profile_pic_container {
        height: 36px;
        width: 36px;
        margin-right: 1.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(black, 0.4);
        border-radius: 50%;
      }

      .profile_detail_container {
        font-weight: 100;
      }
    }
  }

  .menu-box {
    width: 90px;
    position: absolute;
    top: 10px;
    right: 30px;
    box-shadow: 0 0 2px rgba(black, 0.4);
    background-color: white;

    .menu-item {
      padding: 0.3rem 0.6rem;
      display: flex;
      align-items: center;
      justify-content: left;
      border-bottom: 1px solid rgba(grey, 0.2);

      &.edit {
        color: $primary-color;
        cursor: pointer;
      }

      &.delete {
        cursor: pointer;
        color: $danger-color;
      }

      svg {
        margin-left: 5px;
        height: 1rem;
        width: 1rem;
      }
    }
  }
}