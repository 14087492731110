@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

$label-font-size: 5rem;

.main {
  @include flex-box-generic(flex-start, flex-start, column);
  gap: 0.2em;
  width: 100%;
  position: relative;

  input[type="password"] {
    padding-right: 4rem;
  }

  .options {
    width: 100%;
    @include flex-box-generic(flex-start, center, row);
    flex-wrap: wrap;
    // gap: 1em;
    column-gap: 1rem;

    label {
      @include flex-box-generic(flex-start, center, row);

      input {
        margin-right: 5px;
      }
    }
  }

  .labelCon {
    font-size: 1.2rem;
    display: flex;
    gap: 0.3rem;
    span {
      color: red;
    }
    ::-webkit-input-placeholder {
      font-size: 0.7rem;
    }
    input[type="date"],
    input[type="text"] {
      font-size: 0.7rem;
    }
  }
}

.label {
  // font-size: $label-font-size;
  margin: 18px 0;
}
.main {
  position: relative;
}

.input {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 0 !important;

  background-color: transparent;
  border-bottom: 2px solid transparentize($secondary-color, 0.5);
  padding: 0.5rem 0.1rem;
  outline: none;
  color: black;
  font-size: 1rem;
}
.span {
  color: red;
}

.error {
  color: red;
  position: absolute;
  top: 110%;
  left: 0%;
  font-size: 0.8rem;
}

.iconWrapper {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 2%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
