.table_container .profile_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.table_container .profile_pic_container {
  overflow: hidden;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(214, 212, 212);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.search_wrapper {
  align-self: flex-end;
}

.table_container {
  height: 100%;
  min-height: 60vh;
  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.table_container .actions {
  display: block;
  height: 100%;
  min-height: fit-content;
  border: none;
}
.table_container .role {
  height: 20px;
}
