@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.course-form-container {
  width: 100%;
  max-width: 900px;
  height: fit-content;
  padding: 2vw 2vw !important;
  background-color: white;
  border-radius: 1vh;
  @include flex-box-generic(flex-start, flex-start, column);
  gap: 8vh;

  .row-container {
    width: 100%;
    @include flex-box-generic(flex-start, center, row);
    gap: 3vw;

    .fieldAndValidate {
      //   border: 1px solid red;
      width: 100%;
      position: relative;

      p {
        color: red;
        margin-top: 1vh;
        position: absolute;
      }
    }

    .toggleButton {
      font-size: 1.2rem;

      label {
        span {
          color: red;
        }
      }

      width: 30%;
      @include flex-box-generic(flex-end, flex-end, row);

      .switch {
        gap: 1rem;

        @include flex-box-generic(flex-start, flex-start, column);
      }
    }

    .content_image {
      height: 100% !important;
      width: 25%;
      min-width: 120px;
    }

    .button-wrapper {
      @include flex-box-generic(flex-end, flex-end, row);
      gap: 1vw;
      width: 100%;
      margin-top: 0vh !important;

      Button {
        width: 20%;
      }
    }

    .col-container {
      width: 100%;
      // border: 1px solid red;
      @include flex-box-generic(flex-start, center, column);
      gap: 4vh;
    }
  }
}
