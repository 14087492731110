@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.content_page {
  // border: 1px solid red;

  height: 78vh;

  .content_header {
    margin-bottom: 4vh;
    width: 40%;
    h4 {
      margin-bottom: 0;
    }
    @include flex-box-generic(space-between, center, row);
    .buttonWrapper {
      @include flex-box-generic(flex-end, center, row);
      width: 50%;

      .addCourseButton {
        width: 10vw;
      }
    }
  }

  .contents {
    // border: 1px solid red;
    width: 100%;
    height: 100%;

    @include flex-box-generic(space-between, center, row);

    .content_list {
      overflow-y: scroll;
      width: 40%;
      height: 100% !important;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      padding: 1%;
      @include flex-box-generic(flex-start, center, column);
      height: 75vh;
      gap: 10px;
    }

    .content_list::-webkit-scrollbar {
      display: none;
    }

    .content_detail {
      overflow-y: scroll;
      padding: 1em;
      width: 58%;
      height: 100%;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    }
    .content_detail::-webkit-scrollbar {
      display: none;
    }
  }

  .modal_container {
    max-width: 30vw;

    .modalTitle {
      padding: 1vw 2vw;
    }
  }
}
