.add_blog_container {
  .add_blog_header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h3 {
      width: fit-content;
    }
    .back_button_wrapper {
      max-width: 160px;
    }
  }
}
