@use "../../../../assets/styles/abstract/variables" as *;
.quiz_container {
  width: 100%;
  max-width: 700px;
  border: 1px solid grey;
  border-radius: 14px;
  background-color: white;
  padding: 1rem;
  margin: 1rem auto;
}

.field_container {
  display: flex;
  align-items: center;
  h6 {
    line-height: normal;
  }
  input {
    width: 100%;
    border: none;
    margin-bottom: 8px;
    padding-left: 8px;
  }
}

.field_container input:focus {
  outline: none;
}

.answer_container {
  .answer_field {
    padding: 0.5rem 0;
    display: flex;
  }
  input[type="radio"] {
    max-width: 1rem;
  }
  input {
    width: 100%;
    border: none;
    margin-left: 8px;
    background: transparent;
    &:focus {
      outline: none;
      border-bottom: 1px solid grey;
      border-radius: 0;
    }
  }
}

.quiz_container .close_btn {
  display: flex;
  justify-content: flex-end;
  svg {
    padding: 0.6rem;
    width: 24px;
    height: 24px;
    color: red;
    cursor: pointer;
  }
}

.link_input {
  padding: 0 10px;
  max-width: 68px;
  &::placeholder {
    // color: $secondary-color;
  }
}
.link_image {
  // color: $secondary-color;
}

.link_input:focus,
.link_input:not(:placeholder-shown).link_input:not(:focus).link_input {
  max-width: 250px;
}
.link_input:focus ~ .link_image,
.link_input:not(:placeholder-shown).link_input:not(:focus) ~ .link_image {
  display: none;
}

.quiz_container {
  animation-name: fadeShow;
  animation-timing-function: ease;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: initial;
  animation-direction: normal;
}

.quiz_container .error {
  border-radius: 0;
  border-bottom: 1px solid red;
}

@keyframes fadeShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
