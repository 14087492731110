@use "../../../assets/styles/abstract/variables" as *;

.round_header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  h3 {
    margin: 0;
    margin-left: 0.5rem;
  }
  .actions {
    margin-left: auto;
    font-size: 1.5rem;
  }
}
.round_form {
  box-shadow: 0px 0px 4px grey;
  background-color: white;
  padding: 1rem;
  .row_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .input_field {
    margin: 1.8rem 0;
    label {
      display: block;
      width: 100%;
      font-size: 1.1rem;
      margin: 0.1rem 0;
    }
    input {
      width: 100%;
      padding: 0.2rem 0.8rem;
      outline: none;
      border: none;
      border-bottom: 1px solid grey;
    }
    select {
      padding: 0.2rem 0.6rem;
      width: 100%;
    }
    .error {
      color: $danger-color;
      margin: 0;
    }
  }

  .round_time {
    min-width: 200px;
    flex: 1;
    .time_input_container {
      width: 100%;
      max-width: fit-content;
    }
    input {
      max-width: 80px;
    }
    .time_type {
      opacity: 0.5;
    }
  }

  .round_game_type {
    flex: 1;
    min-width: 200px;
  }

  .round_sponsor {
    flex: 0.61;
    min-width: 100px;
  }


  .round_question .question_input_details {
    display: flex;

    gap: 1rem;
    .file_name {
      display: flex;
      align-items: center;
      margin: 0;
      color: $secondary-color;
    }
    .button_upload {
      border: 1px solid $button-color;
      svg {
        margin: 0 0.4rem;
      }
      &:hover {
        border-color: $secondary-color;
        color: $secondary-color;
      }
    }
  }
}
.submit_btn_wrapper {
  margin: 1rem 0;
  margin-top: 2rem;
  margin-left: auto;
  width: 100%;
  max-width: 120px;
  min-width: fit-content;
}

.draggableList {
  background-color: white;
}

.question_input_details {
  display: flex;
  align-items: center;
}
