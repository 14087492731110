@import "../../../../assets/styles/abstract/mixins";
@import "../../../../assets/styles/abstract/variables";

.form_container {
  width: 100%;
  max-width: 1000px;
  padding: 2vw 2vw !important;
  background-color: white;
  border-radius: 1vh;
  @include flex-box-generic(flex-start, flex-start, column);
  gap: 8vh;

  .row_container {
    width: 100%;
    @include flex-box-generic(flex-start, center, row);
    gap: 3vw;

    .field_and_validate {
      width: 100%;
      position: relative;

      p {
        color: red;
        margin-top: 1vh;
        position: absolute;
      }
    }

    .role {
      width: 90%;
    }

    .checkbox {
      width: 110%;
    }

    .button_wrapper {
      @include flex-box-generic(flex-end, flex-end, row);
      gap: 1vw;
      width: 100%;
      margin-top: 1vh !important;

      Button {
        width: 20%;
        min-width: max-content;
      }
    }

    .col_container {
      width: 100%;

      @include flex-box-generic(flex-start, center, column);
      gap: 7vh;
    }
  }
}

.staff_image {
  //this css affects all other images too
  height: 100%;
  max-height: 198px !important;
  width: fit-content;
}

.staff_form_container {
  .row_container:nth-child(1) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    .col_container {
      @media screen and (max-width: 790px) {
        .row_container {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }
    @media screen and (max-width: 478px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .row_container:nth-child(2) {
    @media screen and (max-width: 428px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .row_container:nth-child(3) {
    @media screen and (max-width: 428px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
