.actions {
  display: flex;
  align-items: center;
  padding:0;
  margin:0;
}
.edit_btn {
  height:20px;
  width:20px;
  color: blue;
  cursor: pointer;
  margin-right: 20px;
}

.trash_btn {
  height:20px;
  width:20px;
  cursor: pointer;
  color: red;
}

.modal_container {
  max-height: 90vh;
  min-width: fit-content;

  .modalTitle {
    padding: 1vw 2vw;
  }

  .modalBody {
    max-height: 85vh;
    overflow-y: scroll;
  }

  .modalBody::-webkit-scrollbar {
    display: none;
  }
}
