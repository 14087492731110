.notification_form_container {
  form {
    margin: 1rem 0;
    display: block;
  }
  .input_field {
    max-width: 90%;
    margin: 1.5rem 0;
    & > label {
      font-size: 1.1rem;
    }
  }
  .image_container{
    max-width: 50%;
  }
}
