.add_blog_header {
  h3 {
    font-weight: 500;
  }
}

.blog_form {
  & > h4 {
    margin: 1.5rem 0;
    font-weight: 300;
    font-size: 1.2rem;
  }
  form {
    background-color: #fbfcfc;
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-gap: 1rem;
  }
  .input_field {
    padding: 1rem;
    label {
      margin: 1rem 0;
    }
  }
  .form_detail_container {
    background-color: #ffff;
    box-shadow: 0 5px 15px rgba(grey, 0.2);
    grid-column: 1/9;
    grid-row: 1 / span 2;
    border-radius: 4px;
    .quill {
      height: 336px;
    }
  }

  .blog_image_container {
    background-color: #ffff;
    grid-column: 9/12;
    grid-row: 1/2;
    height: 300px;
    .image_container {
      height: 300px;
      border: 1px solid rgba(grey, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      height: 55%;
      width: 55%;
    }
    img {
      width: 75%;
      height: 75%;
      object-fit: cover;
      border-radius: 12px;
    }
  }
  .form_link_container {
    box-shadow: 0 4px 15px rgba(grey, 0.2);
    background-color: #ffff;
    grid-column: 9/12;
  }
  .submit_btn_wrapper {
    margin-left: auto;
  }
}
