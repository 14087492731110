.custom_radio_button{
    display:block;
    position: relative;
    padding:0 24px 0 0;

    input{
        position: absolute;
        opacity:0;
        height:0;
        width:0;
    }

    /* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .correct_answer{
    height:25px;
    width:25px;
    position: absolute;
    display: none;
  }
  /* When the radio button is checked, add a blue background */
   input:checked ~ .correct_answer {
    display: block;
  }
  /* When the radio button is checked, add a blue background */
   input:checked ~ .checkmark {
    display:none

  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
   input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
   .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

}