@use "../../../assets/styles/abstract/variables" as *;
.round_header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  h3 {
    margin: 0;
    margin-left: 0.5rem;
  }
  .actions {
    margin-left: auto;
    font-size: 1.5rem;
  }
}
.round_form_container {
  .round_form {
    margin: 1rem 0;
    box-shadow: 0px 0px 4px grey;
    background-color: white;
    padding: 1rem;

    .input_field {
      margin: 1rem 0;
      label {
        display: block;
        width: 100%;
        margin: 0.1rem 0;
      }
      input {
        width: 100%;
        padding: 0.2rem 0.8rem;
        outline: none;
        border: none;
        border-bottom: 1px solid grey;
      }
      select {
        padding: 0.2rem 0.6rem;
        width: 100%;
      }
    }

    .round_time {
      .time_input_container {
        width: 100%;
        max-width: fit-content;
      }
      input {
        max-width: 80px;
      }
    }
    .round_question .question_input_details {
      display: flex;

      gap: 1rem;
      .file_name {
        display: flex;
        align-items: center;
        margin: 0;
        color: $secondary-color;
      }
      .button_upload {
        svg {
          margin: 0 0.4rem;
        }
        &:hover {
          border-color: $secondary-color;
          color: $secondary-color;
        }
      }
    }
  }

  .draggableList {
    background-color: white;
  }
}

.add_round {
  .add_form_button_wrapper {
    display: flex;
    justify-content: center;
    margin: 1rem;
    button {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      padding: 0;
      text-align: center;
      &:hover {
        color: $secondary-color;
        border-color: $secondary-color;
      }
    }
  }
}
