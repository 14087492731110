th > span {
  margin-left: 0.5rem;
}

.table_container{
  overflow-x: scroll;
}

.table_container tr {
  animation-duration: 1s;
  animation-direction:normal;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes fade-out {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
