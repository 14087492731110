@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

.toggle {
  --width: 42px;
  --height: calc(var(--width) / 2.5);

  position: relative;
  display: inline-block;
  width: var(--width);
  height: var(--height);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  border-radius: var(--height);
  cursor: pointer;
}

.toggle input {
  display: none;
}

.toggle .slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--height);
  background-color: rgb(230, 230, 230);
  transition: all 0.4s ease-in-out;
}

.toggle .slider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: calc(var(--height));
  height: calc(var(--height));
  border-radius: calc(var(--height) / 2);
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked + .slider {
  background-color: $success-color;
}

.toggle input:checked + .slider::before {
  transform: translateX(calc(var(--width) - var(--height)));
}

.toggle .labels {
  position: absolute;
  top: 2.2px;
  left: 1px;
  text-align: center;
  list-style: 10px;
  width: 100%;
  height: 100%;
  font-size: 8px;
  transition: all 0.4s ease-in-out;
}

.toggle .labels::after {
  content: attr(data-off);
  z-index: 1;
  position: absolute;
  right: 5px;
  color: #000000;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle .labels::before {
  content: attr(data-on);
  z-index: 1;
  position: absolute;
  left: 5px;
  color: #ffffff;
  opacity: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  transition: all 0.4s ease-in-out;
}

.toggle input:checked ~ .labels::after {
  opacity: 0;
}

.toggle input:checked ~ .labels::before {
  opacity: 1;
}
