@use "../../../assets/styles/abstract/variables" as *;
.competition_form {
  padding: 0.3rem 0;
  h3 {
    margin: 2rem 0;
  }
  form {
    margin: auto;
    width: 100%;
    max-width: 716px;
  }
  .required {
    color: $danger-color;
  }
  .error {
    margin: 0.6rem 0;
    color: $danger-color;
  }
  .competition_name_wrapper {
    width: 100%;
    min-width: 300px;
    margin: 2rem 0;
  }
  label {
    font-size: 1rem;
    display: block;
  }
  .date_container {
    margin: 1rem 0;
    display: flex;
    @media screen and (max-width: 630px) {
      flex-wrap: wrap;
    }
    .start_date_container,
    .end_date_container {
      margin-right: 2rem;
      label {
        margin: 1rem 0;
        margin-right: 1rem;
      }
      input {
        padding: 0.6rem;
        height: 36px;
      }
    }
  }

  .competition_round_wrapper {
    // width: 100%;
    display: flex;
    margin: 1rem 0;

    .breaktime_container,
    .round_container{
      margin-right: 2rem;
      label {
        margin: 1rem 0;
        margin-right: 1rem;
      }
      input {
        padding: 0.6rem;
        height: 36px;
      }
    }
    }
  

  .submit_btn_wrapper {
    width: 100%;
    max-width: 170px;
    min-width: 150px;
    margin: 1rem 0;
    margin-top: 2.5rem;
    margin-left: auto;
  }
}
