@import "../../assets/styles/abstract/mixins";
@import "../../assets/styles/abstract/variables";

$label-font-size: 5rem;

.main {
  @include flex-box-generic(flex-start, flex-start, column);
  width: 100%;
  position: relative;
  gap: 0.5rem;
  input[type="password"] {
    padding-right: 4rem;
  }
  .labelCon {
    font-size: 1.2rem;

    display: flex;
    gap: 0.3rem;
    span {
      color: red;
    }
    ::-webkit-input-placeholder {
      font-size: 0.7rem;
    }
  }
}

.label {
  font-size: $label-font-size;
  margin: 8px 0;
}

.input {
  width: 100%;
  border-radius: 15px;
  border: 2px solid transparentize($secondary-color, 0.5);
  padding: 0.5rem 0.5rem;
  resize: none;
  outline: none;
  //   color: $secondary-color;
}

.input[data-border="full-border"] {
  padding: 0.5rem 0.5rem;
  border: 2px solid transparentize($secondary-color, 0.5);
  border-radius: 0.5rem;
}

.error {
  color: red;
  position: absolute;
  bottom: -1rem;
  font-size: 0.8rem;
  left: 0;
}
