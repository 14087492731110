//common scss
@use "../../assets/styles/Common/pageheader.scss";
@use "../../assets/styles/Common/table.scss";

@use "../../assets/styles/abstract/mixins" as *;

.page_header h3 {
  font-size: 1.8rem;
}
.btn_container {
  width: 150px;
  margin-left: auto;
}

.page_container {
  margin-bottom: 5vh;

  @include flex-box-generic(center, center, row);
  .buttonWrapper {
    @include flex-box-generic(flex-end, center, row);
    width: 100%;

    .addStaffButton {
      width: 10vw;
    }
  }
}

.subject_table_container tr td:nth-child(1),
.subject_table_container tr th:nth-child(1) {
  word-wrap: break-word;
  word-break: break-all;
  width: 25%;
  min-width: 190px;
  max-width: 300px;
}
.subject_table_container tr td:nth-child(2),
.subject_table_container tr th:nth-child(2) {
  word-wrap: break-word;
  word-break: break-all;
  width: 40%;
  min-width: 150px;
  max-width: 800px;
}
.subject_table_container tr td:nth-child(3),
.subject_table_container tr th:nth-child(3) {
  word-wrap: normal;
  word-break: normal;
  width: 25%;
  min-width: 140px;
  max-width: 700px;
}
.subject_table_container tr td:nth-child(4),
.subject_table_container tr th:nth-child(4) {
  word-wrap: normal;
  word-break: normal;
  // width: 10%;
  // min-width:300px;
  // max-width: 700px;
}
