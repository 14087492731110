@use "../../assets/styles/abstract/variables" as *;

.show_normal_quiz {
  .file_input {
    display: none;
  }
  .button_wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  .bulk_upload_wrapper {
    width: 100%;
    max-width: 150px;
  }
  .add_question_wrapper {
    width: 100%;
    max-width: 150px;
  }
  .file_name {
    display: flex;
    align-items: center;
  }
  .close_icon_container {
    margin-left: 2rem;

    svg {
      color: $danger-color;
      cursor: pointer;
    }
  }
}
