/* @use "../../assets/styles/abstract/variables" as *;

.login_container {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;

  //mobile view
  @media screen and (max-width:576px) {
    width:100%;
    flex-wrap: wrap;
  }
}
.login_container .image_container {
  width: 30%;
  min-width: 200px;
  
  //mobile view
  @media screen and (max-width:576px) {
    width:100%;
    display: none;
    
  }
}
.login_container .detail_container {
  width: 70%;
  background-color: white;

  //mobile view
  @media screen and (max-width:576px) {
    width:100%;
    
  }
}

.login_container .detail_container {
  padding: 60px 30px;
}

.title_container {
  margin: 20px 0;
  display: flex;
  h1 {
    padding: 0px 4px;
    font-weight: 600;
  }
}

.login_container > .detail_container img {
  height: 50px;
  width: 50px;
}
.login_container .greet {
  margin: 10px 0;
  color: $secondary_color;
}
.login_container .detail_container > p {
  margin: 30px 0px;
  font-size: 1rem;
  line-height: 0.9;
  color: rgb(153, 163, 164);
}
.login_detail_container {
  display: flex;
  gap: 16px;
  padding: 10px 0px;
  @media screen and (max-width:1082px) {
    flex-wrap: wrap;
    
  }
}
.username_container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  label {
    padding: 5px 0px;
  }
  input {
    padding: 6px 12px;
    border: 1px solid #e5e7e9;
    border-radius: 0.25rem;
    &:focus {
      border: 1px solid #828384;
      outline: none;
    }
  }
}
.password_container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  label {
    padding: 5px 0px;
  }
  input {
    border: 1px solid #e5e7e9;
    padding: 6px 12px;
    &:focus {
      border: 1px solid #828384;
      outline: none;
    }
  }
}

.keep_login_container {
  margin: 10px 0px 40px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  input {
    height: 16px;
    width: 16px;
  }
  label {
    padding: 0 10px;
  }
}

 .login_container .button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
}

.recover_password_button {
  font-size: 0.9rem;
}
.button_submit {
  border-radius: 32px;
  margin-left: 1rem;
}

.terms_container {
  position: absolute;
  width: 100%;
  bottom: 0px;
  text-align: right;
  font-size: 1rem;
  line-height: 0.9;
  color: rgb(153, 163, 164);
   
  //mobile view
  @media screen and (max-width:576px) {
  padding:20px;  
  }
} */

//asdasdas

.admin-login__main {
  text-align: center;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .container {
    width: auto;
    display: flex;
    /* margin: 100px auto 0 auto; */
    justify-content: center;
  }

  .left {
    width: 600px;
    height: 600px;
    background-color: rgb(242, 244, 254);
  }

  img {
    width: 500px;
    height: 600px;
    margin-left: 50px;
  }

  .right {
    width: 600px;
    height: 600px;
    background-color: white;
    align-items: center;
    text-align: center;
  }

  .title {
    font-weight: bold;
    margin: 60px 0;
  }

  .first {
    font-size: 45px;
    color: rgb(238, 106, 92);
  }

  .second {
    font-size: 45px;
    color: rgb(62, 148, 202);
  }

  h3 {
    font-family: Arial Rounded MT Bold;
    color: rgb(161, 163, 163);
  }

  .form-group .icons {
    position: absolute;
  }

  .form-group {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    transition: 0.2s ease;
  }

  .icons {
    margin: 12px;
    color: black;
    width: 20px;
    height: 20px;
  }

  .form-input {
    outline: none;
    width: 425px;
    text-align: center;
    font-size: 15px;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid rgb(161, 163, 163);
  }

  .form-input:focus::placeholder {
    color: transparent;
  }

  .form-check-label {
    color: rgb(18, 127, 196);
    margin-left: 10px;
    font-size: 16px;
    margin-right: 110px;
  }

  .form-check-input {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  .form-forget {
    color: rgb(18, 127, 196);
    font-size: 16px;
    text-decoration: underline;
  }

  .btn {
    background-color: rgb(18, 127, 196);
    color: white;
    border: rgb(18, 127, 196);
    width: 425px;
    height: 45px;
    font-size: 15px;
    border-radius: 1px;
    cursor: pointer;
  }

  .arrow {
    position: absolute;
    padding: 5px;
    color: white;
    min-width: 40px;
    min-height: 40px;
  }

  footer {
    margin-top: 20px;
    font-size: 10px;
    color: grey;
    text-align: center;

    p {
      margin: 0;
    }
  }
}

body {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    white 0%,
    white 50%,
    rgb(62, 148, 202) 50%,
    rgb(62, 148, 202) 100%
  );
}
